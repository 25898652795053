import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
import * as i3 from "@angular/router";
var CurrencyService = /** @class */ (function () {
    function CurrencyService(http, apollo, router) {
        this.http = http;
        this.apollo = apollo;
        this.router = router;
        this.shopNoSubject = new BehaviorSubject(null);
    }
    CurrencyService.prototype.getShopNoObservable = function () {
        return this.shopNoSubject.asObservable();
    };
    CurrencyService.prototype.setShop = function (val) {
        this.shopNo = +val;
        this.shopCurrency = this.getCurrencySymbol(val);
        this.shopNoSubject.next(+val);
    };
    CurrencyService.prototype.getCurrencySymbol = function (countryCode) {
        switch (+countryCode) {
            case 49:
                return "CZK";
            case 50:
                return "HUF";
            case 51:
                return "PLN";
            case 52:
                return "RON";
            case 53:
                return "SEK";
            case 54:
                return "₣";
            case 55:
                return "€";
            case 58:
                return "£";
            case 54:
                return "CHF";
            case 56:
                return "R";
            case 57:
                return "DKK";
            default:
                return "-";
        }
    };
    CurrencyService.ngInjectableDef = i0.defineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.inject(i1.HttpClient), i0.inject(i2.Apollo), i0.inject(i3.Router)); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
