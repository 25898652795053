<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <!-- <h1>Login</h1> -->
                <a [routerLink]="[ '/' ]"><img name class="navbar-brand-full" src="assets/img/brand/cellarme-logo.jpg" width="200" height="auto"
                  alt="Port2Port 2.0"></a>
                <p class="text-muted">Reset Password</p>
                <form *ngIf="!this.fragmentBoolean" [formGroup] = "form" (ngSubmit) = "onSubmit(form.value)">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input placeholder="E-mail" type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">E-mail is required</div>
                        <div *ngIf="f.email.errors.email">Must be a valid email address</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
                <form *ngIf="this.fragmentBoolean" [formGroup] = "tokenForm" (ngSubmit) = "onSubmit(tokenForm.value)">
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-lock"></i></span>
                        </div>
                        <input placeholder="Password" type="password" formControlName="password" class="form-control"
                          autocomplete="password" [ngClass]="{ 'is-invalid': submitted && ff.password.errors }" />
                        <div *ngIf="submitted && ff.password.errors" class="invalid-feedback">
                          <div *ngIf="ff.password.errors.required">Password is required</div>
                        </div>
                      </div>
                      <div class="input-group mb-4">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-lock"></i></span>
                        </div>
                        <input placeholder="Repeat Password" type="password" formControlName="confirmPassword" class="form-control"
                          autocomplete="password" [ngClass]="{ 'is-invalid': submitted && ff.confirmPassword.errors }" />
                        <div *ngIf="submitted && ff.confirmPassword.errors" class="invalid-feedback">
                          <div *ngIf="ff.confirmPassword.errors.required">Confirm password is required.</div>
                        </div>
                      </div>
                      <div *ngIf="confirmPassword.valid && tokenForm.invalid && tokenForm.errors.passwordsShouldMatch" class="alert alert-danger">
                          <div>Passwords do not match.</div>
                      </div>
                    <div class="row">
                      <div class="col-6">
                          <button class="btn btn-primary" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
