import { createSelector, MemoizedSelector, defaultMemoize, MemoizedSelectorWithProps } from '@ngrx/store';

import * as fromRoot from 'app/@store';
import * as fromItemsReducer from '../reducer';
import * as fromItems from '../items/items.reducer';

import { Item, ItemBrand, ItemDetailType, Supplier, SupplierItem, ItemImage, valueLabel } from '../../models';
import { Country } from 'app/_models';
import { HighLevelTypes } from 'app/_models/high-level-type';
import { ItemEntitiesValues } from '../items/items.reducer';


export const getItemsState: MemoizedSelector<fromRoot.State, fromItems.ItemsState> = createSelector(
  fromItemsReducer.getItemsFeatureState,
  (state: fromItemsReducer.ItemsFeatureState) => state.items
);

export const selectLoaded: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getItemsState,
  fromItems.getLoaded
);


export const selectMixedCase: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getItemsState,
  fromItems.getMixedCase
);



export const selectLoading: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getItemsState,
  fromItems.getLoading
);

export const selectItems: MemoizedSelector<fromRoot.State, Item[]> = createSelector(
  getItemsState,
  fromItems.getItems
);

export const selectMixedCaseItems: MemoizedSelector<fromRoot.State, Item[]> = createSelector(
  getItemsState,
  fromItems.getMixedCaseItems
);

export const selectItemImages: MemoizedSelector<fromRoot.State, ItemImage[]> = createSelector(
  getItemsState,
  fromItems.getItemImages
);

export const selectItemsEntities: MemoizedSelector<fromRoot.State, { [key in ItemEntitiesValues]: Item[] }> = createSelector(
  getItemsState,
  fromItems.getItemsEntities
);

export const selectItemsEntity: MemoizedSelectorWithProps<fromRoot.State, { entityName: ItemEntitiesValues }, Item[]> = createSelector(
  getItemsState,
  (state, props) => {
    return state.itemsEntities[props.entityName]
  }
);

export const selectCurrentItem: MemoizedSelector<fromRoot.State, Item> = createSelector(
  getItemsState,
  fromItems.getCurrentItem
);

export const selectCurrentMixedCase: MemoizedSelector<fromRoot.State, Item> = createSelector(
  getItemsState,
  fromItems.getCurrentMixedCase
);



export const selectCurrentMixedItem: MemoizedSelector<fromRoot.State, Item> = createSelector(
  getItemsState,
  fromItems.getCurrentMixedItem
);

export const selectBrands: MemoizedSelector<fromRoot.State, ItemBrand[]> = createSelector(
  getItemsState,
  fromItems.getBrands
);

export const selectLabels: MemoizedSelector<fromRoot.State, valueLabel[]> = createSelector(
  getItemsState,
  fromItems.getLabels
);

export const selectHighLevelTypes: MemoizedSelector<fromRoot.State, HighLevelTypes[]> = createSelector(
  getItemsState,
  fromItems.getHighLevelTypes
);

export const selectCountries: MemoizedSelector<fromRoot.State, Country[]> = createSelector(
  getItemsState,
  fromItems.getCountries
);

export const selectSuppliers: MemoizedSelector<fromRoot.State, Supplier[]> = createSelector(
  getItemsState,
  fromItems.getSuppliers
);

export const selectSupplierItem: MemoizedSelector<fromRoot.State, SupplierItem> = createSelector(
  getItemsState,
  fromItems.getSupplierItem
);

export const selectSupplierItemConditionSuggest: MemoizedSelector<fromRoot.State, string[]> = createSelector(
  getItemsState,
  fromItems.getSupplierItemConditionSuggest
);

export const selectItemDetailTypesAll: MemoizedSelector<fromRoot.State, ItemDetailType[]> = createSelector(
  getItemsState,
  fromItems.getItemDetails
);

export const selectItemDetailTypes: MemoizedSelectorWithProps<fromRoot.State, { page: string }, ItemDetailType[]> = createSelector(
  getItemsState,
  (state, props) => {
    // console.log(state.itemDetailTypes)
    if (props) {

      return state.itemDetailTypes.filter(type => type.group == props.page)
    }
    return state.itemDetailTypes
  }
);

export const selectExcelTemplateUrl: MemoizedSelector<fromRoot.State, string> = createSelector(
  getItemsState,
  fromItems.getExcelTemplateUrl
);

export const selectError: MemoizedSelector<fromRoot.State, any> = createSelector(
  getItemsState,
  fromItems.getError
);

export const selectshowDeleteError: MemoizedSelector<fromRoot.State, string> = createSelector(
  getItemsState,
  fromItems.getShowDeleteError
);


export const selectshowDeleteConfirmation: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getItemsState,
  fromItems.getShowDeleteConfirmation
);
