import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CurrencyService } from 'app/_services/currency.service';
import gql from 'graphql-tag';
const allShops = gql`query {Shops (limit:0,sort:"name") { name _id shopNo currency } }`;

@Component({
  selector: 'app-shop-list',
  templateUrl: './shop-list.component.html',
  styleUrls: ['./shop-list.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopListComponent implements OnInit {
  @Input() shopNo;


  shopList: any;
  shopCurrency: string;

  constructor(private apollo: Apollo, public currencyService: CurrencyService) {
    this.apollo.query({
      query: allShops
    }).subscribe(({ data, loading }) => {
      const shops: any = data;
      // this.loading = loading;
      const filteredShops = shops.Shops.filter(shop => shop.shopNo !== 56);
      this.shopList = filteredShops;
      return this.shopList;
    });
  }

  ngOnInit() {
    if (!this.currencyService.shopNo) {
      this.currencyService.setShop(55)
    }
  }

  changeShop() {
    this.currencyService.setShop(this.currencyService.shopNo)
  }






}
