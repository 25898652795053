﻿import { UserType } from './../_models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, UserToken } from '../_models';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class CurrencyService {
  // shopNo: number;
  shopCurrency: string;

  public shopNo: number;
  public shopNoSubject = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router) {
  }

  getShopNoObservable() {
    return this.shopNoSubject.asObservable();
  }


  setShop(val: number) {
    this.shopNo = +val;
    this.shopCurrency = this.getCurrencySymbol(val);
    this.shopNoSubject.next(+val);
  }

  getCurrencySymbol(countryCode) {
    switch (+countryCode) {
      case 49:
        return "CZK";
      case 50:
        return "HUF";
      case 51:
        return "PLN";
      case 52:
        return "RON";
      case 53:
        return "SEK";
      case 54:
        return "₣";
      case 55:
        return "€";
      case 58:
        return "£";
      case 54:
        return "CHF";
      case 56:
        return "R";
      case 57:
        return "DKK";
      default:
        return "-";
    }
  }



}
