import { AlertService } from './alert.service';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { IdleEventManager } from './activity.service';
import { Injector, Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public alertService: AlertService, public auth: AuthenticationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);

          if (event.body.errors && event.body.errors.length > 0) {
            let s = event.body.errors[0].error.message
            // s = s.split(':')[1];
            // console.log('event--->>>', s);
            if ([123].includes(event.body.errors[0].statusCode)) {

              // auto logout if 401 or 403 response returned from api
              // this.tokenService.logout();
              //"404 - {"auth":false,"message":"Failed to authenticate token."}"

              // 404 - {\"message\":\"Missing Orders Summary\"}
            }



            if (event.body.errors[0].error.message === 'Failed to authenticate token. verifyToken' ||
              event.body.errors[0].error.message === '404 - {"auth":false,"message":"Failed to authenticate token."}' ||
              event.body.errors[0].error.message === '500 - {\"auth\":false,\"message\":\"Failed to authenticate token.\"}') {
              this.auth.timeOut();
              // this.auth.showMsg = true;
              // console.log('event--->>>', event.body.errors[0].error.message);
            } else if (event.body.errors[0].error.message === '404 - {\"message\":\"Missing Orders Summary\"}') {
              this.alertService.p2Alert('No orders yet', 'Adjust date selection to see previous orders');
            } else if (s === 'ItemSuppliers Not Found') {
              this.alertService.p2Alert('No Valid Suppliers Found', 'Please pick a different brand');
            } else {
              // document.write(s.slice(0, -1));
              this.alertService.p2Alert('', s);
            }

            // 404 - {\"message\":\"Invalid Item is published\"}

            // console.log('event--->>>', event.body.errors);
          }

          // if (event.body.data) {
          //   this.alertService.success('udpated');
          //   console.log('event--->>>', event.body.data[0]);
          // }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };

        // this.errorDialogService.openDialog(data);
        this.alertService.error(error.error.errors[0].error.message);
        return throwError(error);
      }));
  }
}
