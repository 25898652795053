import * as tslib_1 from "tslib";
import { OnInit, } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CurrencyService } from 'app/_services/currency.service';
import gql from 'graphql-tag';
var allShops = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["query {Shops (limit:0,sort:\"name\") { name _id shopNo currency } }"], ["query {Shops (limit:0,sort:\"name\") { name _id shopNo currency } }"])));
var ShopListComponent = /** @class */ (function () {
    function ShopListComponent(apollo, currencyService) {
        var _this = this;
        this.apollo = apollo;
        this.currencyService = currencyService;
        this.apollo.query({
            query: allShops
        }).subscribe(function (_a) {
            var data = _a.data, loading = _a.loading;
            var shops = data;
            // this.loading = loading;
            var filteredShops = shops.Shops.filter(function (shop) { return shop.shopNo !== 56; });
            _this.shopList = filteredShops;
            return _this.shopList;
        });
    }
    ShopListComponent.prototype.ngOnInit = function () {
        if (!this.currencyService.shopNo) {
            this.currencyService.setShop(55);
        }
    };
    ShopListComponent.prototype.changeShop = function () {
        this.currencyService.setShop(this.currencyService.shopNo);
    };
    return ShopListComponent;
}());
export { ShopListComponent };
var templateObject_1;
