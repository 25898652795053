export class BrandImage {
  brand?: string;
  _id: string;
  file: File;
}

export class SupplierImage {
  supplier?: string;
  _id?: string;
  file?: File;
  supplier_id?: string;
}


export class Brand {
  id: number;
  x: number;
  y: number;
  name: string;
  title: string;
  tagline: string;
  email: string;
  status: Boolean;
  about: string;
  facebook: string;
  instagram: string;
  twitter: string;
  activeStatus: Boolean;
  isActiveInd: Boolean;
  imageUrl: string;
  imageName: string;
  imageEtag: string;
  imageKey: string;
  description: string;
  country_id: string;
  brand_id: string;
  author_id: string;
  titleShort: string;
  body: string;
  shop_id: string;
  region_id: string;
  publishDate: Date;
  cultivar_ids: [];
  idList?: [];
  keywords: [];
  country: {
    name: string;
  };
  dateCreated: Date;
  file?: File;
  item_id: string;
  article_id: string;
}


export class Brands {
  Brand: Brands[];
}


