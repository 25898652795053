<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/cellarme-logo.jpg', width: 140, alt: 'cms'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/cellarme.gif', width: 30, alt: 'cms'}" [sidebarToggler]="'false'"
  [asideMenuToggler]=false [asideMenuToggler]=false [mobileAsideMenuToggler]="false">


  <app-shop-list></app-shop-list>
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a style="text-decoration:underline;" class="nav-link" href="/#/reports/sales-picklist">Picklists</a>
    </li>
  </ul>
  <!-- cil-people -->
  <!-- <ul class="nav navbar-nav mr-2">
    <app-header-links></app-header-links>
  </ul> -->
  <!-- <ul class="nav navbar-nav">
    <a class="nav-link userName" data-toggle="dropdown" href="/#/user-guides" role="button" aria-haspopup="true"
      aria-expanded="false">
      <li class="mr-3 header-stats" style="color:#959595;font-size:12px"> <i style="margin-right: 5px;"
          class="icon-book-open"></i>&nbsp;User Guides</li>
    </a>
  </ul> -->
  <ul class="nav navbar-nav ml-auto">
    <app-header-stats *ngIf="isAdmin"></app-header-stats>
    <li style="display:none" class="nav-item dropdown mr-3" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <i class="cil-people"></i><span *ngIf="this.userNoticeAmount"
          class="badge badge-pill badge-danger">{{this.userNoticeAmount}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Notifications</strong></div>
        <!-- You have {{this.userNoticeAmount}} notifications old -->
        <div class="notice_holder" *ngFor="let item of this.noticeList">
          <a class="dropdown-item" href="/#/dashboard" title="{{item.message}}" (click)="clearNotice(item._id)">
            <i *ngIf="!item.date_read" class="fa fa-bell text-success"></i>
            <i *ngIf="item.date_read" class="fa fa-bell-o text-success"></i>
            <p class="title truncate"><strong>{{item.title}}</strong></p>
            <span class="date">{{item.date_created | date : "dd MMM y hh:mm"}}</span>
            <p *ngIf="item.message" style="height:auto;  white-space: pre-wrap
            ;" class="title">{{ (item.message?.length>48)? (item.message | slice:0:47)+'...':(item.message) }}</p>
          </a>
          <i (click)="archiveNotice(item._id)" class="fa fa-close fa-lg mt-4 pull-right"></i>
        </div>
        <div *ngIf="!this.showNotice" style="position: relative;">
          <a (click)="this.archiveAllNotices()" class="dropdown-header text-center" href="#" title="Clear All">
            <!-- <i class="icon-bell text-success"></i> --><strong>Clear All</strong>
          </a>
          <!-- <i (click)="archiveNotice(item._id)" style="position: absolute; right: 13px; top: 13px;cursor: pointer;margin: 0px !important; cursor: pointer;" class="fa fa-close fa-lg mt-4 pull-right"></i> -->
        </div>
        <a *ngIf="this.showNotice" class="dropdown-item" href="#"><i class="icon-bell text-success"></i>No new
          messages</a>

        <!-- <a class="dropdown-item" href="#"><i class="icon-user-follow text-success"></i> New user registered </a>
        <a class="dropdown-item" href="#"><i class="icon-user-unfollow text-danger"></i> User deleted </a>
        <a class="dropdown-item" href="#"><i class="icon-chart text-info"></i> Sales report is ready </a>
        <a class="dropdown-item" href="#"><i class="icon-basket-loaded text-primary"></i> New client </a>
        <a class="dropdown-item" href="#"><i class="icon-speedometer text-warning"></i> Server overloaded </a> -->
        <!-- <div class="dropdown-header text-center"><strong>Server</strong></div><a class="dropdown-item" href="#">
          <div class="text-uppercase mb-1"><small><b>CPU Usage</b></small></div><span class="progress progress-xs">
            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress-bar bg-info"
              role="progressbar" style="width: 25%"></div>
          </span><small class="text-muted">348 Processes. 1/4 Cores.</small>
        </a><a class="dropdown-item" href="#">
          <div class="text-uppercase mb-1"><small><b>Memory Usage</b></small></div><span class="progress progress-xs">
            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="70" class="progress-bar bg-warning"
              role="progressbar" style="width: 70%"></div>
          </span><small class="text-muted">11444GB/16384MB</small>
        </a><a class="dropdown-item" href="#">
          <div class="text-uppercase mb-1"><small><b>SSD 1 Usage</b></small></div><span class="progress progress-xs">
            <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="95" class="progress-bar bg-danger"
              role="progressbar" style="width: 95%"></div>
          </span><small class="text-muted">243GB/256GB</small>
        </a> -->
      </div>
    </li>
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-list"></i></a>
    </li> -->
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-location-pin"></i></a>
    </li> -->
    <!-- <a class="btn desktop_only" [routerLink]="['/dashboard']"><i class="icons cui-user"></i> &nbsp;{{ auth.getCurrentUserType().name }}</a><a class="btn desktop_only" [routerLink]="['/dashboard']"><i class="icons cui-envelope-closed"></i> &nbsp;{{ auth.getCurrentUserType().email }}</a> -->
    <li class="nav-item dropdown mr-4" dropdown placement="bottom right">
      <a class="nav-link userName" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
        aria-expanded="false" dropdownToggle (click)="false">
        <i class="icons cui-user"></i> &nbsp;{{ authServ.getCurrentUserType().name }}<br />
        <span class="email_holder"><i class="icons cui-envelope-closed"></i> &nbsp;{{
          authServ.getCurrentUserType().email
          }}</span>
        <!-- <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com"/> -->
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a style="font-size: 12px;" (click)="authServ.logout()" id="logout" class="dropdown-item" href="#"><i
            class="fa fa-lock"></i> Logout</a>
      </div>


    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <!-- <app-sidebar-nav [navItems]="auth.getNavItem()" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav> -->
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- <cui-breadcrumb>
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" href="#"><i class="icon-speech"></i></a>
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
        </div>
      </li>
    </cui-breadcrumb> -->
    <app-ng7-dynamic-breadcrumb [bgColor]="'#fff'" [fontSize]="'14px'" [fontColor]="'#20a8d8'"
      [lastLinkColor]="'#73818f'"></app-ng7-dynamic-breadcrumb>
    <!-- deprecation warning for 'app-breadcrumb' -->
    <!-- <ol class="breadcrumb">
    <app-breadcrumb></app-breadcrumb> -->
    <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
    <!--<li class="breadcrumb-menu d-md-down-none">-->
    <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
    <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
    <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
    <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
    <!--</div>-->
    <!--</li>-->
    <!-- </ol> -->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
  <!--  <app-aside [fixed]="true" [display]="false">
    <tabset>
      <tab>
        <ng-template tabHeading><i class="icon-list"></i></ng-template>
        <div class="list-group list-group-accent">
          <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Today</div>
          <div class="list-group-item list-group-item-accent-warning list-group-item-divider">
            <div class="avatar float-right">
              <img class="img-avatar" src="assets/img/avatars/7.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div>Meeting with
              <strong>Lucas</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  1 - 3pm</small>
            <small class="text-muted">
              <i class="icon-location-pin"></i>  Palo Alto, CA</small>
          </div>
          <div class="list-group-item list-group-item-accent-info">
            <div class="avatar float-right">
              <img class="img-avatar" src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div>Skype with
              <strong>Megan</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  4 - 5pm</small>
            <small class="text-muted">
              <i class="icon-social-skype"></i>  On-line</small>
          </div>
          <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Tomorrow</div>
          <div class="list-group-item list-group-item-accent-danger list-group-item-divider">
            <div>New UI Project -
              <strong>deadline</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  10 - 11pm</small>
            <small class="text-muted">
              <i class="icon-home"></i>  creativeLabs HQ</small>
            <div class="avatars-stack mt-2">
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/2.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/3.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/5.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/6.jpg" alt="admin@bootstrapmaster.com">
              </div>
            </div>
          </div>
          <div class="list-group-item list-group-item-accent-success list-group-item-divider">
            <div>
              <strong>#10 Startups.Garden</strong> Meetup</div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  1 - 3pm</small>
            <small class="text-muted">
              <i class="icon-location-pin"></i>  Palo Alto, CA</small>
          </div>
          <div class="list-group-item list-group-item-accent-primary list-group-item-divider">
            <div>
              <strong>Team meeting</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  4 - 6pm</small>
            <small class="text-muted">
              <i class="icon-home"></i>  creativeLabs HQ</small>
            <div class="avatars-stack mt-2">
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/2.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/3.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/5.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/6.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/7.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/8.jpg" alt="admin@bootstrapmaster.com">
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading><i class="icon-speech"></i></ng-template>
        <div class="p-3">
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading><i class="icon-settings"></i></ng-template>
        <div class="p-3">
          <h6>Settings</h6>
          <div class="aside-options">
            <div class="clearfix mt-4">
              <small><b>Option 1</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input" checked>
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
            <div>
              <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 2</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input">
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
            <div>
              <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 3</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input">
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 4</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input" checked>
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
          </div>
          <hr>
          <h6>System Utilization</h6>
          <div class="text-uppercase mb-1 mt-4"><small><b>CPU Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">348 Processes. 1/4 Cores.</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>Memory Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">11444GB/16384MB</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>SSD 1 Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">243GB/256GB</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>SSD 2 Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-success" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">25GB/256GB</small>
        </div>
      </tab>
    </tabset>
  </app-aside> -->
</div>
<app-footer>
  <span class="ml-auto">Powered by <a href="#">CellarMe 1.0</a></span>
</app-footer>
<div (click)="closeAndClear();authServ.showImportModal = !authServ.showImportModal" *ngIf="authServ.showImportModal"
  class="modal_bg"></div>
